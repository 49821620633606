var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fedex-good-detail-page"},[(!_vm.isBatch && _vm.isFristWeight)?_c('div',{staticClass:"header"},[_vm._m(0),_c('ColorTextBtn',{staticClass:"add-btn",on:{"click":_vm.addWeightItem}},[_vm._v(" 添加重量范围 ")])],1):_vm._e(),(!_vm.isBatch && !_vm.isFristWeight)?_c('div',{staticClass:"header"},[_vm._m(1),_c('ColorTextBtn',{staticClass:"add-btn",on:{"click":_vm.addWeightItem}},[_vm._v(" 添加重量范围 ")])],1):_vm._e(),(_vm.isBatch)?_c('div',{staticClass:"table-header"},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.countryName))]),_c('ColorTextBtn',{staticClass:"table-add-btn",on:{"click":_vm.addWeightItem}},[_vm._v(" 添加重量范围 ")])],1):_vm._e(),_c('CommonTable',{attrs:{"height":"auto","selection":false,"cols":_vm.cols,"infoData":_vm.data},scopedSlots:_vm._u([{key:"weightRangeSlot",fn:function(){return [_vm._v(" 重量范围 ")]},proxy:true},{key:"startWeightSlot",fn:function(ref){
var ref_scoped = ref.scoped;
var index = ref_scoped.index;
var prop = ref_scoped.prop;
return [_c('el-input-number',{attrs:{"disabled":"","size":"small","controls-position":"right","min":1},model:{value:(_vm.data[index][prop]),callback:function ($$v) {_vm.$set(_vm.data[index], prop, $$v)},expression:"data[index][prop]"}})]}},{key:"endWeightSlot",fn:function(ref){
var ref_scoped = ref.scoped;
var index = ref_scoped.index;
var prop = ref_scoped.prop;
return [_c('el-input-number',{attrs:{"size":"small","controls-position":"right","min":_vm.data[index].minEndWeight || 1},on:{"change":function($event){return _vm.endWeightSChange($event, index)}},model:{value:(_vm.data[index][prop]),callback:function ($$v) {_vm.$set(_vm.data[index], prop, $$v)},expression:"data[index][prop]"}})]}},{key:"weightSlot",fn:function(ref){
var ref_scoped = ref.scoped;
var index = ref_scoped.index;
var prop = ref_scoped.prop;
return [_c('el-input-number',{attrs:{"size":"small","controls-position":"right","min":1},model:{value:(_vm.data[index][prop]),callback:function ($$v) {_vm.$set(_vm.data[index], prop, $$v)},expression:"data[index][prop]"}})]}},{key:"priceSlot",fn:function(ref){
var ref_scoped = ref.scoped;
var index = ref_scoped.index;
var prop = ref_scoped.prop;
return [_c('el-input-number',{attrs:{"size":"small","controls-position":"right","min":0},model:{value:(_vm.data[index][prop]),callback:function ($$v) {_vm.$set(_vm.data[index], prop, $$v)},expression:"data[index][prop]"}})]}}])},[_c('el-table-column',{attrs:{"fixed":"right","align":"center","label":"操作","width":"100px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var $index = ref.$index;
return [(_vm.showDelBtn($index))?_c('ColorTextBtn',{attrs:{"type":"danger"},on:{"click":function($event){return _vm.delWeightItem($index)}}},[_vm._v(" 刪除 ")]):_vm._e()]}}])})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tip"},[_c('i',{staticClass:"el-icon-warning icon"}),_vm._v(" 运费=首重价格+((计费重量-首重)/续重单位重量)* 续费单位价格+挂号费+紧急情形附加费 ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tip"},[_c('i',{staticClass:"el-icon-warning icon"}),_vm._v(" 运费 = 费用 + 挂号费 + 紧急情形附加费 ")])}]

export { render, staticRenderFns }