<template>
  <div style="display: none">
    <BaseDialog minHeight="200px" :title="title" :dialogVisible.sync="dialog" width="80vw">
      <template>
        <FedexGoodList :data="data" />
      </template>
      <template #footer>
        <LoadingBtn @click="cancel"> 取消 </LoadingBtn>
        <el-button :loading="loading" type="primary" @click="doSubmit"> 确认 </el-button>
      </template>
    </BaseDialog>
    <paymentPassBox
      :loading.sync="loading"
      :passwordVal.sync="passwordVal"
      :payVisible.sync="visible"
      @commitPassword="commitPassword"
    ></paymentPassBox>
  </div>
</template>
<script>
import FedexGoodList from '../fedexGoodList'
import paymentPassBox from '@/views/components/payDialog'
import { editImageTypeFiled as formField } from './field'
import { commonFromMixin } from '@/mixins'
import { bulkUpdate } from '@/api/template'
import { passwordSetted } from '@/api/balanceApi'
import { mapGetters } from 'vuex'

export default {
  mixins: [commonFromMixin],

  components: {
    FedexGoodList,
    paymentPassBox
  },

  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      formField,
      loading: false,
      passwordVal: [],
      visible: false,
      form: {
        name: ''
      }
    }
  },
  computed: {
    ...mapGetters(['is_super'])
  },
  methods: {
    async commitPassword() {
      const data = Object.values(this.data)
      let list = []
      data.map((item) => {
        const fristObj = item[0]
        item.map((sItem) => {
          for (let key in fristObj) {
            if (!sItem[key] && key != 'id') {
              sItem[key] = fristObj[key]
            }
          }
        })
        list = list.concat(item)
      })

      const countryIdList = [...new Set(list.map(({ countryId }) => countryId))].filter(Boolean)
      const templateId = [...new Set(list.map(({ templateId }) => templateId))].filter(Boolean)
      let postData = {
        countryIdList,
        templateId: templateId[0],
        list
      }
      if (!this.is_super) {
        const approveConfirmPassword = this.processPaymentPassword()
        if (!approveConfirmPassword) {
          return
        }
        postData = Object.assign(postData, { approveConfirmPassword })
      }
      this.loading = true
      try {
        const { code } = await bulkUpdate(postData)
        if ($SUC({ code })) {
          this.success()
        }
      } catch (err) {
        console.log('err', err)
      }
      this.loading = false
    },
    processPaymentPassword() {
      let pwd = ''
      this.passwordVal
        .filter((item) => {
          return item !== ''
        })
        .map((item) => {
          pwd += item
        })
      if (pwd.length !== 6) {
        this.$message.warning('请输入完整密码')
        return undefined
      } else {
        return pwd
      }
    },
    async doSubmit() {
      try {
        if (!this.is_super) {
          const { code, detail } = await passwordSetted({})
          if (!$SUC(code)) {
            // 如果当前登录用户没有设置交易密码，则提示跳转并return
            if (detail == 0) {
              this.$message.warning('当前登录用户未设置交易密码')
            } else {
              this.passwordVal = []
              this.visible = true
            }
          }
        } else {
          this.commitPassword()
        }
      } catch (error) {}
    }
  }
}
</script>
